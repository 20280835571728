import type { ChangeEvent } from "react";
import React from "react";
import ReactDOM from "react-dom";
import type { BaseFieldProps, WrappedFieldInputProps } from "redux-form";
import { change, Field, formValueSelector } from "redux-form";
import type { EditorProps } from "components/editorComponents/CodeEditor";
import { CodeEditorBorder } from "components/editorComponents/CodeEditor/EditorConfig";
import type { AppState } from "@appsmith/reducers";
import { connect } from "react-redux";
import get from "lodash/get";
import merge from "lodash/merge";
import uniqBy from "lodash/uniqBy";
import type { EmbeddedRestDatasource, Datasource } from "entities/Datasource";
import type {
  EditorTheme,
  HintHelper,
} from "components/editorComponents/CodeEditor/EditorConfig";
import { EditorSize } from "components/editorComponents/CodeEditor/EditorConfig";
import styled from "styled-components";

import { getCurrentApplicationId } from "selectors/editorSelectors";
import { Colors } from "constants/Colors";
import { Indices } from "constants/Layers";
import type { DataTree } from "entities/DataTree/dataTreeTypes";
import { getDataTree } from "selectors/dataTreeSelectors";
import equal from "fast-deep-equal/es6";
import {
  getDatasource,
  getDatasourcesByPluginId,
} from "@appsmith/selectors/entitiesSelector";
import { getCurrentAppWorkspace } from "@appsmith/selectors/selectedWorkspaceSelectors";
import DropdownField from "components/editorComponents/form/fields/DropdownField";
import DynamicTextField from "./DynamicTextField";

interface ReduxStateProps {
  workspaceId: string;
  datasource: Datasource | EmbeddedRestDatasource;
  datasourceList: Datasource[];
  applicationId?: string;
  dataTree: DataTree;
  actionName: string;
  formName: string;
  userWorkspacePermissions: string[];
  pluginName?: string;
  datasourceFromApi?: any;
}

interface ReduxDispatchProps {
  updateDatasource: (datasource: Datasource | EmbeddedRestDatasource) => void;
}

type Props = EditorProps &
  ReduxStateProps &
  ReduxDispatchProps & {
    input: Partial<WrappedFieldInputProps>;
    pluginId: string;
    codeEditorVisibleOverflow: boolean; // this variable adds a custom style to the codeEditor when focused.
  };

const DatasourceContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  height: 36px;
  .t--datasource-editor {
    background-color: var(--ads-v2-color-bg);
    .cm-s-duotone-light.CodeMirror {
      background: var(--ads-v2-color-bg);
    }
    .CodeEditorTarget {
      z-index: ${Indices.Layer5};
    }
  }

  .t--store-as-datasource {
    margin-left: 10px;
  }
`;

const DPathWrap = styled.div`
  width: 400px;
  .t--datasource-editor > div {
    min-height: 36px;
    & > div {
      height: 100%;
    }
  }
`;

const DropdownSelect = styled.div`
  font-size: 14px;
  width: 20%;

  .t--switch-datasource > div {
    min-height: 36px;

    & .appsmith-select__input > input {
      position: relative;
      bottom: 4px;
    }

    & .appsmith-select__input > input[value=""] {
      caret-color: transparent;
    }
  }
`;

class EmbeddedDatasourcePathComponent extends React.Component<
  Props,
  { highlightedElementWidth: number }
> {
  constructor(props: Props) {
    super(props);
    this.state = { highlightedElementWidth: 0 };
  }

  // if the next props is not equal to the current props, do not rerender, same for state
  shouldComponentUpdate(nextProps: any, nextState: any) {
    if (!equal(nextProps, this.props)) {
      return true;
    }
    if (!equal(nextState, this.state)) {
      return true;
    }
    return false;
  }

  render() {
    const {
      codeEditorVisibleOverflow,
      datasource,
      datasourceFromApi,
      input: { value },
      pluginName,
      userWorkspacePermissions,
      dataTree
    } = this.props;
    // const datasourceUrl = get(datasourceFromApi, "formData.url", "");
    let displayValue: string = get(
      datasourceFromApi,
      "formData.service.pathname",
      "",
    );

    const queries = get(datasourceFromApi, "queryParameters", []);
    // 使用map方法遍历数组并将每个对象转换为查询字符串的一部分
    const queryString = queries
      ?.filter((it: any) => it.key)
      .map(
        (obj: any) =>
          `${encodeURIComponent(obj.key)}=${encodeURIComponent(obj.value)}`,
      )
      .join("&");

    if (queries.filter((it: any) => it.key).length) {
      displayValue = displayValue + `?${queryString}`;
    }
    const input = {
      ...this.props.input,
      value: displayValue,
    };

    // const shouldSave = datasource && !("id" in datasource);

    // const canCreateDatasource = hasCreateDatasourcePermission(
    //   userWorkspacePermissions,
    // );

    const datasourcePermissions = datasource?.userPermissions || [];

    const isDubbo =
      get(datasourceFromApi, "formData.serviceType", "") === "dubbo";

    const protocol = isDubbo ? "dubbo" : "http";

    const serviceName = get(
      datasourceFromApi,
      "formData.service.serviceName",
      "",
    );
    const appName = get(datasourceFromApi, "formData.appName", "");
    const serviceVersion = get(
      datasourceFromApi,
      "formData.serviceVersion",
      "",
    );

    const method = get(datasourceFromApi, "formData.method", "");

    const IPPORT_OPTIONS = uniqBy(
      get(datasourceFromApi, "formData.service.providers", []),
      ["ip", "port"],
    ).map((it: any) => {
      return {
        value: protocol + "://" + it?.ip + ":" + it?.port,
        label: protocol + "://" + it?.ip + ":" + it?.port,
      };
    });

    return (
      <DatasourceContainer data-replay-id={btoa(input.name || "")}>
        <div className="flex gap-1 w-full ">
          {isDubbo ? (
            <div className="flex rounded items-center justify-center w-[100px] text-lg border border-slate-300">
              {protocol}
            </div>
          ) : (
            <span />
          )}
          <DropdownSelect>
            <DropdownField
              className={"t--switch-datasource"}
              formName={`actionConfiguration.formData.service.origin`}
              isDisabled={false}
              name={`actionConfiguration.formData.service.origin`}
              options={IPPORT_OPTIONS}
              placeholder="实例"
            // width={isDubbo ? 400 : 300}
            // components={{ MenuList, Option: CustomOption, SingleValue }}
            />
          </DropdownSelect>
          {isDubbo ? (
            <div className="flex py-1 px-3 rounded  truncate items-center w-3/5 bg-slate-100 gap-2 border border-slate-300 ">
              <div className="text-primary-400">{appName}</div>
              <div>{serviceName}</div>
              {method ? <div className="text-slate-500">/{method}</div> : null}
              <div className="text-slate-400">:</div>
              <div className="justify-self-end">{serviceVersion}</div>
            </div>
          ) : (
            <DPathWrap>
              <DynamicTextField
                border={CodeEditorBorder.ALL_SIDE}
                className="t--datasource-editor"
                dataTreePath={"actionConfiguration.formData.service.pathname"}
                hoverInteraction
                name={"actionConfiguration.formData.service.pathname"}
                placeholder={"path"}
                size={EditorSize.COMPACT_RETAIN_FORMATTING}
              />
            </DPathWrap>
          )}
        </div>
      </DatasourceContainer>
    );
  }
}

const mapStateToProps = (
  state: AppState,
  ownProps: { pluginId: string; actionName: string; formName: string },
): ReduxStateProps => {
  const apiFormValueSelector = formValueSelector(ownProps.formName);
  const datasourceFromAction = apiFormValueSelector(state, "datasource");
  const pluginName = apiFormValueSelector(state, "pluginName");
  const datasourceFromApi = apiFormValueSelector(state, "actionConfiguration");

  let datasourceMerged = datasourceFromAction || {};
  // Todo: fix this properly later in #2164
  if (datasourceFromAction && "id" in datasourceFromAction) {
    const datasourceFromDataSourceList = getDatasource(
      state,
      datasourceFromAction.id,
    );
    if (datasourceFromDataSourceList) {
      datasourceMerged = merge(
        {},
        datasourceFromAction,
        datasourceFromDataSourceList,
      );
    }
  }

  return {
    workspaceId: state.ui.workspaces.currentWorkspace.id,
    datasource: datasourceMerged,
    datasourceList: getDatasourcesByPluginId(state, ownProps.pluginId),
    applicationId: getCurrentApplicationId(state),
    dataTree: getDataTree(state),
    actionName: ownProps.actionName,
    formName: ownProps.formName,
    userWorkspacePermissions:
      getCurrentAppWorkspace(state)?.userPermissions ?? [],
    pluginName,
    datasourceFromApi,
  };
};

const mapDispatchToProps = (
  dispatch: any,
  ownProps: any,
): ReduxDispatchProps => ({
  updateDatasource: (datasource) =>
    dispatch(change(ownProps.formName, "datasource", datasource)),
});

const EmbeddedDatasourcePathConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmbeddedDatasourcePathComponent);

function EmbeddedDatasourcePathField(
  props: BaseFieldProps & {
    pluginId: string;
    placeholder?: string;
    theme: EditorTheme;
    actionName: string;
    codeEditorVisibleOverflow?: boolean;
    formName: string;
  },
) {
  return (
    <Field component={EmbeddedDatasourcePathConnectedComponent} {...props} />
  );
}

export default EmbeddedDatasourcePathField;
