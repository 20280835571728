export const MENU_KEY = "menu";
export const PAGE_KEY = "list";
export const initTree = [];

export const defaultLayout = {
  color: "#553DE9",
  logoUrl: "",
  name: "My first application",
  treeData: initTree,
  outsiderTree: [
    { title: "页面2", pageId: "651411c507a3b253aef739f9", isPage: true },

    { title: "页面2333", pageId: "651412c507a3b253aef739f9", isPage: true },
    { title: "页面3ee2", pageId: "651413c507a3b253aef73939", isPage: true },
    { title: "页面32", pageId: "651411c507a3b253aef73939", isPage: true },
  ],
};

// 转换树形数据为Map数据
export function translateData(tree: any = [], treeKey: string) {
  const result: any = {};

  tree.forEach((node: any) => {
    const { title, pageId, key, isHidden } = node;
    const index = pageId;
    // 页面节点
    result[pageId] = {
      index: pageId,
      canMove: true,
      isFolder: false,
      data: index,
      title,
      key: pageId,
      canRename: true,
      isHidden: !!isHidden,
      icon: node?.icon,
    };
    return result;
  });

  const filstLevel = tree.map((n: any) => n.pageId);
  // 顶级节点
  result[treeKey] = {
    index: treeKey,
    canMove: true,
    isFolder: true,
    children: filstLevel,
    data: treeKey,
    canRename: true,
    key: treeKey,
    title: treeKey,
    isHidden: false,
    icon: null,
  };

  return result;
}

// 转换树形数据为Map数据
export function convertData(tree: any, treeKey: string) {
  const result: any = {};
  const _tree = tree.map((t: any) => ({
    ...t,
    key: t.pageId || generateUuid(),
  }));

  function traverse(node: any, parentKey?: any) {
    const { title, pageId, isPage, key, children, isHidden, slug } = node;
    const index = pageId || key;
    if (isPage) {
      result[index] = {
        index,
        canMove: true,
        isFolder: false,
        data: index,
        title,
        key,
        canRename: false,
        isHidden: !!isHidden,
        icon: node?.icon,
        slug: slug || title,
      };
    } else {
      result[index] = {
        index,
        canMove: true,
        isFolder: true,
        children: null,
        data: index,
        title,
        key,
        canRename: true,
        isHidden: !!isHidden,
        icon: node?.icon,
        slug: slug || title,
      };
    }
    if (parentKey) {
      if (!result[parentKey].children) {
        result[parentKey].children = [];
        result[parentKey].children.push(index);
      } else {
        result[parentKey].children.push(index);
      }
    }
    if (children && children.length > 0) {
      children.forEach((child: any) => traverse(child, index));
    }
  }
  _tree.forEach((node: any) => traverse(node));
  const filstLevel = _tree.map((n: any) => n.key);
  result[treeKey] = {
    index: treeKey,
    canMove: true,
    isFolder: true,
    children: filstLevel,
    data: treeKey,
    canRename: true,
    key: treeKey,
    title: treeKey,
    isHidden: false,
    icon: "",
    slug: treeKey.slice(0, 4),
  };

  return result;
}

export function generateUuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
export function generateNode() {
  const treeKey = generateUuid();
  return {
    index: treeKey,
    canMove: true,
    isFolder: true,
    children: [],
    data: treeKey,
    canRename: true,
    key: treeKey,
    title: "新建目录",
    isHidden: false,
    icon: null,
    slug: treeKey.slice(0, 4),
  };
}

export function reverseConvertData(convertedData: any, treeKey: string) {
  const result: any = [];

  function traverse(index: any) {
    const node = convertedData[index] || {};
    const { isFolder, isHidden } = node;

    if (isFolder) {
      const { index: key, children = [], title, icon, slug } = node;
      const newNode = { title, key, children, isHidden, icon, slug };
      const _children: any[] = [];
      children &&
        children.forEach((child: any) => {
          _children.push(traverse(child));
        });
      newNode.children = _children;
      result.push(newNode);
      return newNode;
    } else {
      const { index: key, title, icon, slug } = node;
      const newNode = {
        title,
        pageId: index,
        slug,
        isPage: true,
        key,
        isHidden,
        children: [],
        icon,
      };

      result.push(newNode);
      return newNode;
    }
  }

  traverse(treeKey);
  return result;
}

const readTemplate = (template: any, data: any = { items: {} }) => {
  for (const [key, value] of Object.entries(template)) {
    data.items[key] = {
      index: key,
      canMove: true,
      isFolder: value !== null,
      children: value !== null ? Object.keys(value as object) : undefined,
      data: key,
      canRename: true,
      title: `P-${key}`,
      icon: null,
    };

    if (value !== null) {
      readTemplate(value, data);
    }
  }
  return data;
};

const nodeTreeTemplate = {
  [PAGE_KEY]: {
    page1: null,
    page2: null,
    page3: null,
    page4: null,
  },
};

export const menuNodes = convertData(initTree, MENU_KEY);
export const lists = readTemplate(nodeTreeTemplate);
export const originalData = reverseConvertData(menuNodes, MENU_KEY);
