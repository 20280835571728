import React, { useState, useEffect } from "react";
import { DeleteOutlined, EyeOutlined, InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import type { GetProp, UploadProps } from "antd";
import styled from "styled-components";
type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const getBase64 = (img: FileType, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};
const UploadContainer = styled.div`
  height: 100%;
  width: 100%;
`;
const colorLine1 = "#eee";
const colorLine2 = "#f7f7f7";
const StyledImageBox = styled.div`
  display: block;
  position: relative;
  padding: 8px 0px;
  text-align: center;
  background: linear-gradient(
    45deg,
    ${colorLine1} 5%,
    ${colorLine2} 0,
    ${colorLine2} 10%,
    ${colorLine1} 0,
    ${colorLine1} 15%,
    ${colorLine2} 0,
    ${colorLine2} 20%,
    ${colorLine1} 0,
    ${colorLine1} 25%,
    ${colorLine2} 0,
    ${colorLine2} 30%,
    ${colorLine1} 0,
    ${colorLine1} 35%,
    ${colorLine2} 0,
    ${colorLine2} 40%,
    ${colorLine1} 0,
    ${colorLine1} 45%,
    ${colorLine2} 0,
    ${colorLine2} 50%,
    ${colorLine1} 0,
    ${colorLine1} 55%,
    ${colorLine2} 0,
    ${colorLine2} 60%,
    ${colorLine1} 0,
    ${colorLine1} 65%,
    ${colorLine2} 0,
    ${colorLine2} 70%,
    ${colorLine1} 0,
    ${colorLine1} 75%,
    ${colorLine2} 0,
    ${colorLine2} 80%,
    ${colorLine1} 0,
    ${colorLine1} 85%,
    ${colorLine2} 0,
    ${colorLine2} 90%,
    ${colorLine1} 0,
    ${colorLine1} 95%,
    ${colorLine2} 0,
    ${colorLine2} 100%,
    ${colorLine1} 0
  );
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;
  display: flex;
  align-content: center;
  justify-content: center;
  height: 100%;
  .mask {
    position: absolute;
    background: rgba(101, 101, 101, 0.6);
    border-radius: 8px;
    color: #fff;
    opacity: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  &:hover .mask {
    opacity: 1;
  }
  > span:hover {
    color: red;
  }
`;
const beforeUpload = (file: FileType) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

interface UploaderImage {
  title: string;
  des: string;
  data: any;
  onDataChange: (value: string) => void;
}

const App: React.FC<UploaderImage> = (props) => {
  const { data, des, onDataChange, title } = props;
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();

  const handleChange: UploadProps["onChange"] = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      if (info.file.response.data) {
        const imgUrl = `/api/v1/assets/${info.file.response.data}`;
        setLoading(false);
        setImageUrl(imgUrl);
        onDataChange(imgUrl);
      }
    }
  };

  const handleImage = (type?: any) => {
    if (type === "delete") {
      setImageUrl("");
      onDataChange("");
    }
    return;
  };

  useEffect(() => {
    setImageUrl(data);
  }, [data]);

  return (
    <UploadContainer>
      {imageUrl ? (
        <StyledImageBox>
          <img alt="avatar" src={imageUrl} style={{ width: "auto" }} />
          <div className="mask flex justify-center items-center gap-2">
            <DeleteOutlined onClick={() => handleImage("delete")} />
            {/* <EyeOutlined onClick={(e) => handleImage(e, "check")} /> */}
          </div>
        </StyledImageBox>
      ) : (
        <Upload.Dragger
          action="/api/v1/assets"
          beforeUpload={beforeUpload}
          className="avatar-uploader w-full"
          listType="picture-card"
          name="file"
          onChange={handleChange}
          showUploadList={false}
        >
          <div className="text-gray-400">
            <p className="ant-upload-drag-icon">
              <InboxOutlined style={{ fontSize: 30 }} />
            </p>
            <p className="ant-upload-hint">{title}</p>
            <span className="ant-upload-hint">{des}</span>
          </div>
        </Upload.Dragger>
      )}
    </UploadContainer>
  );
};

export default App;
